<template>
  <div class="Userhl_fapiaolie Userhl_rightbox">
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="100" :selectable="checkSelect">
      </el-table-column>
      <el-table-column prop="class_idnum" label="支出类型" style="">
      </el-table-column>
      <el-table-column prop="money" label="支出金额" style="">
      </el-table-column>
      <el-table-column prop="create_time" label="支出时间"></el-table-column>
    </el-table>
    <div class="shenqingfapiaobtn">
      <el-button @click="shenqingfp">申请发票</el-button>
    </div>
    <el-pagination
      :pager-count="5"
      @current-change="currentchange"
      :page-size="pagesize"
      background
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
    <el-dialog title="发票信息" :visible.sync="dialogVisible" width="40%">
      <div class="shenqingfapiao_pro">
        <p>
          <span>发票抬头</span>
          <el-input v-model="rise" placeholder="请输入内容"></el-input>
        </p>
        <p>
          <span class="xinyongbianmaspan">社会统一信用编码（税号）</span>
          <el-input v-model="code" placeholder="请输入内容"></el-input>
        </p>
        <p>
          <span>发票信息</span>
          <el-input v-model="invoice_info" placeholder="请输入内容"></el-input>
        </p>
        <p>
          <span>发票金额</span>
          <el-input
            :disabled="true"
            v-model="money"
            placeholder="请输入内容"
          ></el-input>
        </p>
        <p>
          <span>收票地址</span>
          <el-input v-model="address" placeholder="请输入内容"></el-input>
        </p>
        <p>
          <span>开户银行</span>
          <el-input
            v-model="bank_of_deposit"
            placeholder="请输入内容"
          ></el-input>
        </p>
        <p>
          <span>银行账户</span>
          <el-input v-model="bank_account" placeholder="请输入内容"></el-input>
        </p>
        <p>
          <span>联系电话</span>
          <el-input v-model="phone" placeholder="请输入内容"></el-input>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="subfp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tableData: [],
      pagesize: 10,
      total: 0,
      dialogVisible: false,
      input: "",
      money: 0,
      orderid: [],
      rise: "",
      code: "",
      invoice_info: "",
      address: "",
      bank_of_deposit: "",
      bank_account: "",
      phone: "",
      userinfo: {},
    };
  },
  methods: {
    handleSelectionChange(rel) {
      console.log(rel);
      var that = this;
      that.money = 0;
      that.orderid = [];
      var num = 0;
      for (var i = 0; i < rel.length; i++) {
        num = Number(rel[i].money);
        that.money = that.money + num;
        that.orderid.push(rel[i].id);
      }
    },
    axiosqq(page) {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/myWallet",
        method: "post",
        data: {
          page,
          limit: that.pagesize,
          pay_state: "1",
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.total = res.data.result.count;
          for (var i = 0; i < res.data.result.list.length; i++) {
            if (res.data.result.list[i].class_id == 1) {
              res.data.result.list[i].class_idnum = "项目任务";
            } else if (res.data.result.list[i].class_id == 4) {
              res.data.result.list[i].class_idnum = "购买作品";
            } else if (res.data.result.list[i].class_id == 6) {
              res.data.result.list[i].class_idnum = "购买简历";
            } else if (res.data.result.list[i].class_id == 7) {
              res.data.result.list[i].class_idnum = "购买课程";
            } else if (res.data.result.list[i].class_id == 10) {
              res.data.result.list[i].class_idnum = "保证金";
            } else if (res.data.result.list[i].class_id == 11) {
              res.data.result.list[i].class_idnum = "余额提现";
            }
          }
          that.tableData = res.data.result.list;
        }
      });
    },
    subfp() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/applyInvoice",
        method: "post",
        data: {
          orderid: that.orderid,
          rise: that.rise,
          stast: that.stast,
          unified_social_credit_code: that.code,
          invoice_info: that.invoice_info,
          money: that.money,
          address: that.address,
          phone: that.phone,
          bank_of_deposit: that.bank_of_deposit,
          bank_account: that.bank_account,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message("申请已提交，审核中！");
          that.dialogVisible = false;
          setTimeout(() => {
            location.reload();
          }, 500);
        }
      });
    },
    shenqingfp() {
      var that = this;
      if (that.money > 0) {
        that.dialogVisible = true;
      } else {
        this.$message("请选择需要开票的类型");
      }
    },
    currentchange(index) {
      this.axiosqq(index);
    },
    checkSelect(row, index) {
      let isChecked = true;
      if (row.class_id == '11'||row.invoice == '1') {
        // 判断里面是否存在某个参数
        isChecked = false;
      } else {
        isChecked = true;
      }
      return isChecked;
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.axiosqq(1);
  },
};
</script>
